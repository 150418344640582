import * as React from 'react';
import { Box } from '@mui/material';
import './Logo.css';

export default function Logo() {
  return (
    <Box className={'logo_rotate'} sx={{ width: 40, height: 40 }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="100%"
        height="100%"
        viewBox="-0.5 -0.5 890 890"
      >
        <defs />
        <g>
          <ellipse
            cx="444.82"
            cy="444.83"
            rx="404.825"
            ry="404.825"
            fill="none"
            stroke="#ccffff"
            strokeWidth="80"
            pointerEvents="all"
          />
          <path
            d="M 172.5 222.5 L 717.5 532.5 L 172.5 842.5 Z"
            fill="none"
            stroke="#ccffff"
            strokeWidth="80"
            strokeMiterlimit="10"
            transform="rotate(90,445,532.5)"
            pointerEvents="all"
          />
          <path
            d="M 167.5 52.5 L 722.5 362.5 L 167.5 672.5 Z"
            fill="none"
            stroke="#ccffff"
            strokeWidth="80"
            strokeMiterlimit="10"
            transform="rotate(270,445,362.5)"
            pointerEvents="all"
          />
          <path
            d="M 167.5 52.5 L 722.5 362.5 L 167.5 672.5 Z"
            fill="none"
            stroke="#99ffff"
            strokeWidth="50"
            strokeMiterlimit="10"
            transform="rotate(270,445,362.5)"
            pointerEvents="all"
          />
          <path
            d="M 167.5 52.5 L 722.5 362.5 L 167.5 672.5 Z"
            fill="none"
            stroke="#ffffff"
            strokeWidth="20"
            strokeMiterlimit="10"
            transform="rotate(270,445,362.5)"
            pointerEvents="all"
          />
          <path
            d="M 172.5 222.5 L 717.5 532.5 L 172.5 842.5 Z"
            fill="none"
            stroke="#99ffff"
            strokeWidth="50"
            strokeMiterlimit="10"
            transform="rotate(90,445,532.5)"
            pointerEvents="all"
          />
          <path
            d="M 172.5 222.5 L 717.5 532.5 L 172.5 842.5 Z"
            fill="none"
            stroke="#ffffff"
            strokeWidth="20"
            strokeMiterlimit="10"
            transform="rotate(90,445,532.5)"
            pointerEvents="all"
          />
          <ellipse
            cx="444.82"
            cy="444.83"
            rx="404.825"
            ry="404.825"
            fill="none"
            stroke="#99ffff"
            strokeWidth="50"
            pointerEvents="all"
          />
          <ellipse
            cx="444.82"
            cy="444.83"
            rx="404.825"
            ry="404.825"
            fill="none"
            stroke="#ffffff"
            strokeWidth="20"
            pointerEvents="all"
          />
        </g>
      </svg>
    </Box>
  );
}
