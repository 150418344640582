import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Drawer, Typography, Stack, useMediaQuery } from '@mui/material';
import Logo from '../../components/Logo';
import { DefaultTheme } from 'styled-components/native/dist';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

// ----------------------------------------------------------------------

interface SidebarProps {
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;
}
export default function Sidebar({
  isOpenSidebar,
  onCloseSidebar,
}: SidebarProps) {
  const isDesktop = useMediaQuery((theme: DefaultTheme) =>
    theme.breakpoints.up('lg')
  );

  const renderContent = (
    <Box>
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
        <Typography variant="h5" sx={{ mx: 1, my: 'auto' }}>
          Magic Circle
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{ pt: 5, borderRadius: 2, position: 'relative' }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Touch or click the screen to draw
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
