import React, { useMemo } from 'react';
import './App.css';
import CanvasLayout from './layouts/canvas';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';

function App() {
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'dark',
        },
      }),
    []
  );
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CanvasLayout />
    </ThemeProvider>
  );
}

export default App;
