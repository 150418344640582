import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import BackspaceIcon from '@mui/icons-material/Backspace';
import PaletteIcon from '@mui/icons-material/Palette';
import { cyan, purple, yellow } from '@mui/material/colors';
import { useWindowSize } from 'react-use';

/**
 * 描画のカスタムフック
 */
const useCanvas = (size: { width?: number; height?: number } | null) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [ctx, setCtx] = useState<CanvasRenderingContext2D>();
  const [drawing, setDrawing] = useState<boolean>(false);
  const paths = [...Array(12)].map(() => new Path2D());

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current?.getContext('2d');
      if (ctx) {
        setCtx(ctx);
        setup(ctx, canvasRef.current);
      }
    }
  }, [size]);

  /**
   * 初期セットアップ
   * @param ctx 2D描画のコンテキスト
   * @param canvas canvasの要素
   */
  const setup = (ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement) => {
    if (size) {
      canvas.width = size.width || 0;
      canvas.height = size.height || 0;
    }
    canvas.style.borderRadius = '20px';

    ctx.lineWidth = 5;
    ctx.lineCap = 'round';
    ctx.strokeStyle = 'white';
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  };

  /**
   * 原点から極座標で回転させる
   * @param x
   * @param y
   * @param angle 角度(deg)
   * @returns 回転した座標 (canvasが取得できないときはnull)
   */
  const rotate = (x: number, y: number, angle: number) => {
    if (canvasRef.current) {
      const center = {
        x: canvasRef.current.width / 2,
        y: canvasRef.current.height / 2,
      };
      const radians = (Math.PI / 180) * angle;
      const cos = Math.cos(radians);
      const sin = Math.sin(radians);
      const nx = (x - center.x) * cos - (y - center.y) * sin + center.x;
      const ny = (y - center.y) * cos + (x - center.x) * sin + center.y;
      return { x: nx, y: ny };
    } else {
      return null;
    }
  };

  /**
   * 描画開始
   */
  const startDrawing = (x: number, y: number) => {
    setDrawing(true);
    paths.map((path, index) => {
      const p = rotate(x, y, 30 * index);
      p && path.moveTo(p.x, p.y);
    });
  };

  /**
   * ラインを引く（デスクトップ）
   */
  const drawLine = (x: number, y: number, color: Color) => {
    if (!drawing) {
      return;
    }
    if (ctx) {
      paths.map((path, index) => {
        const p = rotate(x, y, 30 * index);
        if (p && canvasRef.current) {
          const r =
            color === 'cyan'
              ? Math.floor(255 * (p.x / canvasRef.current.width))
              : 255;
          const g =
            color === 'purple'
              ? Math.floor(255 * (p.x / canvasRef.current.width))
              : 255;
          const b =
            color === 'yellow'
              ? Math.floor(255 * (p.x / canvasRef.current.width))
              : 255;
          ctx.strokeStyle = `rgb(${r},${g},${b})`;
          path.lineTo(p.x, p.y);
          ctx.stroke(path);
        }
      });
    }
  };

  /**
   * 描画終了
   */
  const endDrawing = () => {
    paths.map((path) => path.closePath());
    setDrawing(false);
  };

  /**
   * キャンバスをクリアする
   */
  const cleanCanvas = () => {
    if (canvasRef.current && ctx) {
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      setup(ctx, canvasRef.current);
    }
  };

  return {
    canvasRef,
    startDrawing,
    drawLine,
    endDrawing,
    cleanCanvas,
  };
};

const getIconColor = (c: Color) => {
  if (c === 'cyan') {
    return cyan[200];
  }
  if (c === 'purple') {
    return purple.A100;
  }
  if (c === 'yellow') {
    return yellow[600];
  }
  return null;
};

export type Color = 'cyan' | 'purple' | 'yellow' | 'white';

/**
 * 描画のコンポーネント
 */
const Canvas = () => {
  /** Boxのサイズ取得用ref */
  const ref = useRef<HTMLDivElement>(null);
  const [color, setColor] = useState<Color>('white');
  const [size, setSize] = useState<{ width: number; height: number } | null>(
    null
  );
  const { width, height } = useWindowSize();
  const { canvasRef, startDrawing, drawLine, endDrawing, cleanCanvas } =
    useCanvas(size);

  // windowに合わせてcanvasのサイズを設定
  useEffect(() => {
    if (ref.current) {
      setSize({
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight,
      });
    }
  }, [width, height]);

  return (
    <Box
      ref={ref}
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <canvas
        ref={canvasRef}
        onMouseDown={(e) =>
          startDrawing(e.nativeEvent.offsetX, e.nativeEvent.offsetY)
        }
        onTouchStart={(e) =>
          startDrawing(e.touches[0].clientX, e.touches[0].clientY)
        }
        onMouseMove={(e) =>
          drawLine(e.nativeEvent.offsetX, e.nativeEvent.offsetY, color)
        }
        onTouchMove={(e) =>
          drawLine(e.touches[0].clientX, e.touches[0].clientY, color)
        }
        onMouseUp={endDrawing}
        onMouseLeave={endDrawing}
        onTouchEnd={endDrawing}
        onDoubleClick={cleanCanvas}
      />
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Box sx={{ flexGrow: 1 }} />
        {['white', 'cyan', 'purple', 'yellow'].map((c) => (
          <Box key={c}>
            <Tooltip title={c}>
              <IconButton
                aria-label={c}
                size="large"
                onClick={() => setColor(c as Color)}
              >
                <PaletteIcon
                  sx={{
                    color: getIconColor(c as Color),
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        ))}
        <Box>
          <Tooltip title="Clear">
            <IconButton aria-label="clear" size="large" onClick={cleanCanvas}>
              <BackspaceIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default Canvas;
